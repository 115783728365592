body {
  font-family: "Montserrat", sans-serif;
}
/* body h1 {
  font-family: "Red Hat Display, sans-serif";
} */

.mainContainer {
  margin: 0;
  position: relative;
  z-index: 1;
  padding: 0;
  color: black;
  transition: background-color 2000ms ease-in-out;
}
.mainContainerActive {
  /* background-color: aqua; */
  background-color: rgb(255, 250, 220);
  /* background-color: rgb(250, 241, 188); */
  transition: background-color 500ms ease-in-out;
}
.mainContainer h1,
.mainContainerActive h1 {
  /* font-family: "Red Hat Display", sans-serif; */
  color: rgba(26, 76, 44, 1);
  font-size: 40px;
  /* margin: 5rem 0; */
  /* padding: 2rem; */
  /* margin-top: 10rem; */
}
.comingSoon {
  position: relative;
}

.comingSoon .toolTipText {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}

.comingSoon:hover .toolTipText {
  visibility: visible;
}
.homePageAllCategoriesBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-bottom: 10px double orange; */
}
.homePageAllCategoriesBox h1 {
  /* border: 5px;
  border-image-source: url("../../Images//tjBorder1.png");
  border-image-slice: fill 30; */
  padding: 1rem 0;
  /* padding-bottom: 0; */
  margin: 3rem 0;
  position: relative;
  /* width: fit-content; */
  text-align: center;
}

.trendingBorder {
  width: 65%;
  height: 4px;
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: peru;
  /* position: relative; */
}
.trendingBorder2 {
  left: 0;
  bottom: -15px;
  background-color: peru;
}
.bestDesignsCorner {
  /* width: 100%; */
  position: absolute;
  height: 7.5rem;
  top: -25px;
}
.leftCorner {
  left: -60px;
}
.rightCorner {
  right: -60px;
}
/* .TJ img {
  width: 32rem;
  height: 6.5rem;
  position: absolute;
  top: -1rem;
  left: 33%;
} */
.imgC {
  /* position: absolute; */
  width: 8rem;
  top: -1.5rem;
  left: 35%;
  /* background-color: white; */
}
.imgC2 {
  /* top: -2.5rem; */
  /* top: -3.5rem; */
  left: 57%;
}

/* .bgActive {
  background-color: yellow;
  transition: background-color 1s ease-in-out;
}
.bgNull {
  background-color: white;
  transition: background-color 1s ease-in-out;
} */
.headingBox {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
}
.caraouselMain {
  width: 100vw;
  height: 70vh;
  background-color: rgb(183, 182, 182);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: x-large;
}
.caraousalImages {
  /* height: 70vh; */
  cursor: pointer;
  width: 100%;
  position: relative;
}
.mySwiper button {
  position: absolute;
  right: 40px;
  padding: 1rem 2rem;
  bottom: 40px;
  cursor: pointer;
}
span.swiper-pagination-bullet {
  width: 1.2rem;
  height: 1.2rem;
  opacity: 2;
  color: black;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-image: url("../../Images/Active-Bullet.png");
}
.categoriesMainContainer {
  display: flex;
  width: 100%;
  margin: 1rem 0;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  /* border: 2px solid red; */
  /* margin-bottom: 2rem; */
}
.category {
  /* height: 150px; */
  /* height: 8rem; */
  width: 31vw;
  /* background-image: url("../../Images/Categories/Category1.jpg"); */
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
}
.category1 {
  /* height: 150px; */
  /* height: 10rem; */
  /* width: 31vw; */
  margin: 1rem 0rem;
  /* z-index: 1; */
}
.category1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(52, 11, 149, 0.4);
  transition: transform ease-in 200ms;
  transform: scaleX(0.5);
  transform-origin: right;
  z-index: 1;
}

.category1:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}
.category2 {
  /* height: 150px; */
  /* width: 31vw; */
  cursor: pointer;
  position: relative;
  /* z-index: 1; */
}
.category2::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);
  transition: transform ease-in 300ms;
  transform: scale(0);
  transform-origin: center;
  z-index: 1;
}

.category2:hover::after {
  transform: scaleX(1);
  transform-origin: initial;
}

.otherCategoriesMain {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  place-items: center;
  margin: 1rem 0;
  width: 100%;
}
.otherCategoriesItems1 {
  width: 95%;
  /* height: 800px; */
  /* margin: 1rem; */
  /* background-image: url("../../Images/OtherCategories/SquareBox1.jpg"); */
  /* background-size: contain; */
  position: relative;
  cursor: pointer;
}
.otherCategoriesItems1::after,
.otherCategoriesItems2::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(0);
  transform-origin: left;
  transition: 400ms ease-in-out;
  z-index: 1;
}
.otherCategoriesItems2::after {
  transform-origin: right;
}
.otherCategoriesItems1:hover::after,
.otherCategoriesItems2:hover::after {
  transform: scale(1);
}
.trendingJewelleryMain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  box-sizing: border-box;
  /* border: 2px solid green; */
}

.trendingJewellerySmallMain {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 750px;
  margin: 1rem;
  padding: 0;
  /* border: 2px solid red; */
  box-sizing: border-box;
  margin-right: 0;
}
.trendingJewellerySmallItems {
  width: 300px;
  margin: 1rem;
  /* border: 2px solid orange; */
  border-radius: 20px;
  background-image: url("https://via.placeholder.com/300");
}
.trendingJewelleryBig {
  width: 40rem;
  /* border: 2px solid yellow; */
  margin: 1rem;
  margin-left: 0;
  border-radius: 30px;
  background-image: url("https://via.placeholder.com/600");
  box-sizing: border-box;
  justify-self: center;
}
h1 {
  color: #292b2c;
}
.bestDesignMain {
  width: 100%;
  height: 70vh;
  margin: 2rem 0;
  margin-bottom: 0;
  display: flex;
}
.bestDesignItems {
  width: 25vw;
  box-sizing: border-box;
  height: 100%;
  background-image: url("https://via.placeholder.com/600");
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  background-size: cover;
}
.bestDesignItems1 {
  background-image: url("../../Images/BestDesigns/BestDesign1.jpg");
}
.bestDesignItems2 {
  background-image: url("../../Images/BestDesigns/BestDesign2.jpg");
}
.bestDesignItems3 {
  background-image: url("../../Images/BestDesigns/BestDesign3.jpg");
}
.bestDesignItems4 {
  background-image: url("../../Images/BestDesigns/BestDesign4.jpg");
}
.bestDesignItems:hover {
  width: 40%;
  transition: all 750ms;
}
.bestGifts {
  box-sizing: border-box;
  width: 100vw;
  background-image: url("https://via.placeholder.com/1020");
}
.whyUsMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-items: center;
  margin-top: 3rem;
  width: 100%;
  background-color: rgb(255, 250, 220);
  box-sizing: border-box;
}
/* #1A4C2D Green Colour */
/* rgb(255, 250, 220) Cream Colour */
.whyUsImage {
  width: 100%;
}
.customerComments {
  margin: 2rem 0rem;
  width: 60vw;
  height: 30vh;
  display: flex;
}
.commentsSlide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.comments {
  margin: 4rem;
  padding: 1rem;
  margin-bottom: 0rem;
}
.comments p {
  font-size: 20px;
  /* font-family: "Monsterrat, Light"; */
  border: none;
}
.comments h1 {
  margin-top: 1rem;
  /* font-family: "Red Hat Display, sans-serif"; */
  font-size: 40px;
  border: none;
}
.contactUsMain {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  place-content: center;
  place-items: center;
  margin-top: 2rem;
  margin-bottom: 6rem;
}
/* .contactUsMain p {
  /* font-family: "Red Hat Display, sans-serif"; */
/* font-weight: bold; */
/* font-size: large; */
/* }  */
.contactUsIcon {
  display: flex;
  color: #1a4c2d;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  border: 1px solid black;
  border-radius: 2.5rem;
  margin: 1rem 4rem;
  position: relative;
  cursor: pointer;
}
.contactUsIcon:hover,
.contactUsIcon:hover a {
  color: white;
  transition-delay: 250ms;
}
.contactUsIcon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(0);
  z-index: -1;
  transition: 500ms;
}
.contactUsIcon:hover::after {
  transform: scale(1);
  background-color: #1a4c2d;
}
.contactUsIcon a {
  color: #1a4c2d;
}
.contactUsIcon a

/* .feedback {
  width: 35px;
  height: 90px;
  box-sizing: border-box;
  position: fixed;
  color: white;
  border-radius: 0 22px 22px 0;
  top: 37vh;
  right: 0;
  z-index: 3;
  background-color: #1a4c2d;
  padding: 0.2rem;
  overflow: hidden;
}
.feedback {
  font-weight: bold;
  padding-right: 10px;
  font-size: x-small;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
} */
.link2 a {
  cursor: pointer;
  text-decoration: none;
  font-weight: normal;
  color: black;
}
@media (max-width: 650px) {
  .caraousalImages {
    height: 10rem;
    width: 100%;
  }
  .homePageAllCategoriesBox h1 {
    margin: 2rem 3rem;
  }
  .category {
    width: 85%;
  }
  .otherCategoriesMain {
    display: flex;
    flex-direction: column;
  }
  .otherCategoriesItems {
    height: 50vh;
    margin: 1rem;
    width: 100%;
  }
  .homePageAllCategoriesBox h1,
  .homePageAllCategoriesBox h2,
  .homePageAllCategoriesBox h1 img {
    font-size: 2rem;
  }
  .leftCorner {
    left: 0;
  }
  .rightCorner {
    right: 0;
  }
  .trendingJewellerySmallMain {
    width: 75%;
    padding-top: 50px;
  }
  .trendingJewellerySmallItems {
    /* width: 40%; */
    width: 120px;
    height: 120px;
    margin: 5px;
    /* height: 40%; */
    /* aspect-ratio: 1; */
    /* 
    height: 10rem;
    height: 20%; */
  }
  .trendingJewelleryBig {
    /* width: 90%; */
    /* height: 90%; */
    margin: 10px;
    aspect-ratio: 1;
    width: 300px;
    height: 300px;
  }
  .bestDesignMain {
    display: flex;
    height: 10rem;
    /* flex-direction: column; */
  }
  .bestDesignItems {
    width: 100%;
    /* margin: 1rem; */
    /* height: 10rem; */
  }
  .bestDesignItems1,
  .bestDesignItems2,
  .bestDesignItems3,
  .bestDesignItems4 {
    width: 25vw;
    height: 10rem;
  }
  .customerComments {
    height: 400px;
    width: 100%;
  }
  .commentsSlide p {
    font-size: 18px;
  }
  .commentsSlide h1 {
    font-size: 24px;
  }
  .contactUsItems {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mySwiper button {
    right: 0;
    bottom: 0;
    padding: 5px;
  }
}
