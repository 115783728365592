.checkOutMainBox {
  /* background-color: rgba(255, 250, 220, 0.1); */
  background-color: #c3e9d11f;
  /* background-color: rgb(255, 250, 220, 0.8); */
  background-color: white;
  /* font-family: "Red Hat Display, sans-serif"; */
}
.checkOutOptionsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: left;
  /* border: 1px solid black; */
  padding: 1rem 0;
  margin-bottom: 5rem;
}
.checkOutMainBox h1 {
  padding-top: 1rem;
}
.checkOutOptionsContainer h3 {
  color: black;
}
.checkOutOptionsContainer input {
  width: 100%;
  height: 2.5rem;
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  box-sizing: border-box;
}
.forgotPasswordBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0;

  /* border: 1px solid yellowgreen; */
}
.forgotPasswordBox input {
  width: 1rem;
  height: 1rem;
  margin: 0 0.5rem;
  margin-left: 0;
}
.forgotPasswordBox a {
  color: black;
}
.checkOutOptionsContainer button {
  width: 100%;
  height: 3rem;
  margin: 1rem 0;
  background-color: black;
  transition: all ease-in-out 200ms;
  color: white;
  font-weight: bolder;
  cursor: pointer;
}
.checkOutOptionsContainer button:hover {
  background-color: #1a4c2d;
}
.returningCustomerBox {
  width: 30%;
  /* height: 40vh; */
  margin: 1rem;
  padding: 1rem;
  /* border: 2px solid green; */
  background-color: white;
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.4);
}
.otherCustomerOptionsBox {
  width: 40%;
  /* height: 60vh; */
  margin: 1rem;
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
}
.otherCustomerOptionsBox button {
  color: black;
  background-color: white;
  transition: all ease-in-out 200ms;
  position: relative;
}

.otherCustomerOptionsBox button:hover {
  background-color: #1a4c2d;
  color: white;
}

.newCustomerBox {
  width: 100%;
  /* height: 50vw; */
  padding: 1rem;
  /* border: 2px solid orange; */
  box-sizing: border-box;
  margin-bottom: 1rem;
  background-color: white;
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.4);
}
.newCustomerCheckIcon {
  margin-right: 1rem;
}
.guestCheckOutBox {
  width: 100%;
  /* height: 50vw; */
  padding: 1rem;
  /* border: 2px solid yellow; */
  box-sizing: border-box;
  background-color: white;
  box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.4);
}
.newCustomerP {
  margin-bottom: 0.5rem;
}
.orderSummarySectionBox {
  display: flex;
  justify-content: space-around;
  /* border: 2px solid black; */
  text-align: left;
  margin-bottom: 5rem;
}
.orderSummarySectionBox h1 {
  text-decoration: underline;
}
.orderSummarySectionBox h2 {
  padding: 0.5rem 1.5rem;
}
.orderSummarySectionBox h3 {
  color: black;
  margin: 0 1rem;
}
.orderSummaryLeftsection {
  width: 40vw;
  height: 40vh;
  /* border: 2px solid red; */
  padding: 2rem;
}
.orderSummaryLeftsection h1 {
  margin-bottom: 2rem;
}
.orderSummaryRightsection {
  /* width: 40vw; */
  /* height: 40vh; */
  /* border: 2px solid orange; */
  padding: 2rem;
}
.orderSummaryRightsection h3 {
  color: rgba(0, 0, 0, 0.7);
}
.orderSummaryProductBox {
  display: flex;
  width: 100%;
  /* border: 2px solid black;    */
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid grey;
}
.orderSummaryProductLeftBox {
  width: 80%;
  text-align: left;
  /* border: 2px solid red; */
}
.orderSummaryProductRightBox {
  /* width: 40%; */
  text-align: right;
  /* border: 2px solid orange; */
}
.orderSummaryProductBoxMain {
  display: flex;
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid grey;
}
.orderSummaryProductBoxMain img {
  width: 7rem;
  height: 7rem;
  border-radius: 0.5rem;
}
.orderSummaryProductBoxDetails p {
  /* text-decoration: underline;/ */
  margin-left: 1.5rem;
}
.orderSummaryProductBoxDetails span {
  text-decoration: none;
  margin-left: 1rem;
}
.orderSummaryProductBoxPrice {
  text-align: right;
}
.orderValueBox h3 {
  text-align: justify;
  width: 100%;
  /* border: 2px solid black; */
}
.chooseDeliveryOptionsBox {
  width: 60%;
  margin: 2rem;
  padding: 1rem;
  border: 1px solid grey;
  background-color: white;
  position: relative;
}
.chooseDeliveryOptionsBox h3,
.chooseDeliveryOptionsBox h2 {
  color: black;
  text-align: left;
}
.infoSymbol {
  position: absolute;
  right: 1rem;
  /* top: 50%; */
  bottom: 40%;
}

/* Account Details CSS */
.accountDetailsMainBox {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 250, 220, 0.493);
  width: 100%;
  position: relative;
  /* height: 1000px; */
  padding: 2rem;
  /* border: 2px solid red; */
}
.accountDetailsBackArrow {
  position: absolute;
  left: 5%;
  top: 2%;
}
.accountDetailsFormBox {
  width: 35%;
  /* border: 2px solid yellow; */
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
  padding: 2rem;
}
.accountDetailsFormBox form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, auto);
  grid-gap: 1rem;
  margin-top: 3rem;
  text-align: left;
  box-sizing: border-box;
}
.accountDetailsFormBox label {
  font-size: 20px;
  /* margin: 5px;  */
  color: rgba(0, 0, 0, 0.6);
}
.accountDetailsFormBox input,
.accountDetailsFormBox select {
  margin: 0rem;
  padding: 0.5rem;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
  /* width: 80%; */
}
.accountDetailsFormBox select {
  width: 100%;
}
.accountDetailsFormBox textarea {
  width: 95%;
  margin: 0;
}
.accountDetailsBtn {
  margin-top: 2rem;
  padding: 1rem;
  font-size: 20px;
  text-align: center;
}
/* .orderSummaryBoxMain {
  width: 30vw;
  height: 50rem;
  border: 2px solid green;} */
.orderDetailsBox {
  width: 30vw;
  text-align: left;
  height: fit-content;
  margin: 0 2rem;
}

/* Ai  */
.orderSummaryProductBoxMain {
  display: grid;
  position: relative;
  /* grid-template-columns: repeat(3, 1fr); */
  grid: auto-flow / 1fr 3fr 1fr;
  grid-gap: 20px;
  grid-column: 2 / span 2;
}
.orderSummaryProductBoxDetails h2 {
  margin-left: 0;
  font-size: 24px;
  margin-bottom: 0.5rem;
}
.orderSummaryProductBoxDetails p {
  margin-left: 0;
}
.orderSummaryMinusIcon {
  position: absolute;
  bottom: 20px;
  right: 10px;
  cursor: pointer;
}

/* .checkoutMainContainer */

.checkoutMainContainer {
  width: 100%;
  color: rgba(0, 0, 0, 0.7);
  /* height: 50vh; */
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  justify-content: space-evenly;
  align-items: center;
  align-items: flex-start;
  text-align: left;
  background-color: rgb(255, 250, 220, 0.2);
  padding: 2rem;
  box-sizing: border-box;
  /* border: 2px solid yellow; */
}
.allProductOrderMainCheckoutBox {
  display: flex;
  flex-direction: column;
}
.orderSummaryCheckoutMain {
  /* width: 45vw; */
  /* height: 10rem; */
  background-color: white;
  /* border: 1px solid rgba(0, 128, 0, 0.6); */
  border: 1px solid rgba(128, 128, 128, 0.281);
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem;
  border-radius: 0.2rem;
  padding: 1rem;
  /* border-right: 1px solid rgba(0, 0, 0, 0.8); */
}
.orderSummaryCheckoutImage {
  height: 8rem;
  width: 6rem;
  /* margin: 0.5rem; */
  border-radius: 0.2rem;
}
.orderSummaryCheckoutImageBox {
  display: flex;
  /* width: 50%; */
  /* border: 2px solid red; */
  justify-content: space-evenly;
  align-items: center;
  /* padding: 0.5rem; */
  /* align-items: center; */
}
.orderSummaryCheckoutImageBox p {
  margin: 0.5rem;
  margin-left: 1rem;
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
  /* color: black; */
}
.orderSummaryCheckoutProductDetailsBox select {
  width: 4rem;
  margin: 0.2rem;
  /* margin-left: 0; */
  margin-right: 1rem;
  /* padding: 2px; */
  border: none;
}
.orderSummaryCheckoutProductDetailsButtons {
  /* width: 15rem; */
  display: flex;
  height: 8rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  /* border: 2px solid orange; */
}
.orderSummaryCheckoutProductDetailsButtons button {
  width: 12rem;
  height: 2rem;
  /* padding: 0.5rem; */
  background-color: white;
  color: grey;
  border: 1px solid #1a4c2d;
  margin: 0.5rem 2rem;
  cursor: pointer;
}
.orderSummaryCheckoutProductDetailsButtons button:hover {
  border: 2px solid green;
  color: #1a4c2d;
  color: green;
}
#redRemoveBtn:hover {
  color: red;
  border-color: red;
}

.orderSummaryCheckoutRightContainer {
  width: 30vw;
  /* margin-top: 10%; */
  position: relative;
  /* height: 10rem; */
  height: 70vh;
}
.orderSummaryCheckoutRightContainer h3 {
  margin: 0.5rem 0;
}

.orderSummaryCheckoutMainBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  /* padding: 1rem 0; */
}
.orderSummaryCheckoutMainBox p {
  margin: 0.5rem 0;
  padding: 0 1rem;
  /* text-align: left; */
}
.box1 {
  text-align: left;
}
.box2 {
  text-align: right;
}
.checkoutSecurelyBtn {
  /* position: absolute; */
  /* margin: 2rem 1rem; */
  /* margin-right: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-size: 16px;
  /* width: calc(30vw-2rem); */
  width: 30vw;
  width: calc(30vw - 2rem);
  margin: 1rem;

  background-image: radial-gradient(
    farthest-corner at 40px 40px,
    #398a58 0%,
    #0b1a0f 100%
  );
  border-radius: 0.3rem;
  border: 1px solid black;
  color: rgba(255, 253, 253, 0.8);
  cursor: pointer;
}
.checkoutSecurelyBtn h4 {
  display: flex;
}
.checkoutSecurelyBtn:hover {
  background-image: radial-gradient(
    farthest-corner at 40px 40px,
    #368153 50%,
    #0b1a0f 100%
  );
  color: rgba(255, 253, 253, 1);
  border: 2px solid green;
}
.checkoutFullPageFormSideMainBox {
  display: flex;
  width: 100%;
  /* height: 100rem; */
  box-sizing: border-box;
  position: relative;
  /* border: 2px solid red; */
}
.checkoutFullPageFormSideBox {
  width: 100%;
  /* height: 150vh; */
  /* background-color: rgba(255, 250, 220, 0.2); */
  /* background-color: white; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  /* padding: 1rem; */
}

.checkoutFullPageOrderSummaryBox {
  width: 30%;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.19);
  /* height: 100rem; */
  /* border: 2px solid yellow; */
  background-color: white;
  /* position: sticky; */
  top: 0;
  right: 0;
  text-align: left;
  padding: 1rem;
  padding-bottom: 3rem;
}
.secondCheckoutImage {
  width: 4rem;
  height: 5rem;
}
.orderSummaryCheckoutMain2 {
  justify-content: space-between;
  border: none;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.subTotalAmountBox {
  text-align: right;
  display: flex;
  align-items: center;
}
.box1new p,
.box2new p {
  color: rgba(0, 0, 0, 0.7);
}
.orderSummaryCheckoutMainBox2 {
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.checkoutFullPageFormSideBox h3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  text-align: left;
  padding: 1rem 0;
  /* background-color: #1a4c2d; */
  /* color: white; */
  /* border: 2px solid red; */
  /* background-color: #1a4c2d; */
  color: black;
  /* border-bottom: 2px solid wheat; */
  /* box-shadow: inset 0px 1px 20px black; */
}
.loginPageBox {
  width: 20rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  /* height: 25rem; */
  color: rgba(0, 0, 253, 0.075);
  padding: 1rem;
  display: flex;
  margin-top: 5rem;
  margin-bottom: 5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px solid orange; */
}
.loginSocialMediaOptionsBox {
  /* position: absolute; */
  width: 100%;
  height: 2rem;
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
  /* justify-content: center; */
  /* bottom: 0; */

  /* flex-direction: column; */
  /* border: 2px solid red; */
}
.loginSocialMediaOptionsBox button {
  width: 45%;
  margin-inline: 8px;
  /* padding: 1.2rem 0.5rem; */
  padding: 16px 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border-radius: 2px;
  justify-content: left;
  align-items: center;
  /* box-shadow: 1px 1px 2px grey; */
}
.loginSocialMediaOptionsBox button:hover {
  /* box-shadow: 1px 1px 2px grey inset; */
  border: 1px solid green;
}

.loginFormCheckoutFullPage {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.loginPageBox hr {
  margin-top: 1rem;
  width: 80%;
}
.loginPageBox p {
  color: #1a4c2d;
  margin: 0.5rem 1.5rem;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: 8px;
  font-size: 14px;

  /* align-self: first baseline; */
  /* color: rgba(0, 0, 0, 0.7); */
  font-weight: bold;
}
.loginPageBox input {
  width: 90%;
  height: 2.5rem;
  padding: 0 0.5rem;
  border-radius: 2px;
  border: 1px solid grey;
  /* border-color: rgb(255, 250, 220, 0.5); */
  /* margin-top: 1rem; */
  /* margin-bottom: 8px; */
}
.loginFormCheckoutGridBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}
.loginFormCheckoutGridBox input {
  width: 80%;
}
.checkoutLoginBtn {
  width: 95%;
  /* margin-inline: 2rem; */
}
.createAccountLink {
  font-weight: normal;
  color: #368153;
  cursor: pointer;
}
.createAccountLink:hover {
  transition: color ease-in-out 300ms;
  color: black;
  text-decoration: underline;
}
.genderRadioBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.genderRadioBox label {
  color: grey;
  padding-top: 6px;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5rem;
  background-color: #0b1a0f35;
}
.progress {
  position: absolute;
  bottom: 0;
  /* width: 70rem; */
  height: 0.5rem;
  background-color: #1a4c2d;
}
.registerBox {
  width: 35rem;
  display: grid;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  /* grid-gap: 10px; */
}
.registerBox p {
  /* margin-inline: 0; */
  justify-self: flex-start;
}
.registerBoxMain {
  /* place-items: center; */
  width: max-content;
  width: 30rem;
}
.loginPageBox textarea {
  width: 80%;
  margin-inline: 0;
  height: 5rem;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 0.2rem;
  /* border-color: rgb(255, 250, 220); */
  /* border-color: grey; */
  /* box-shadow: 1px 1px 2px grey inset; */
}
.setPasswordMain {
  width: 25rem;
}
.payentPagePayBtnBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.payBtn {
  font-size: 16px;
}
.orderReviewBoxForPayment {
  width: 30rem;
  /* height: 100%; */
  padding: 2rem;
  background-color: white;
  box-shadow: -1px 0px 10px grey;
  /* border: 2px solid green; */
}
.orderSummarySideBoxForPayment {
  width: 25%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  background-color: white;
  /* border: 2px solid black; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.loginFormCheckoutGridBox2 {
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.loginFormCheckoutGridBox2 p {
  text-align: right;
}
.onlyDeliveryOptionMainBox {
  /* padding-top: 20px; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* border: 2px solid red; */
  /* background-color: rgba(255, 250, 220, 0.493); */
}
.onlyDeliveryOptionMainBox h3 {
  margin: 0rem;
  padding: 1rem;
  margin-bottom: 0;
  cursor: pointer;
}

.contentForDeliveryBox {
  text-align: center;
  width: 95%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  margin: 0.5rem;
  margin-top: 0;
  background-color: rgba(255, 250, 220);
}
.accountDetailsFormBox {
  width: 400px;
}
.selected {
  box-shadow: 2px 2px 7px grey;
}
.unSelected {
  box-shadow: inset 2px 4px 5px grey;
  background-color: rgba(255, 250, 220);
  transition: box-shadow ease-in-out 450ms;
}

/* CheckoutNewMain */

.checkoutNewLoginMainBox {
  /* height: 500px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  /* border: 2px solid red; */
  /* background-color: rgba(128, 128, 128, 0.1); */
  background-color: rgb(255, 250, 220, 0.5);
}
.checkoutNewLoginFormBox {
  min-width: 350px;
  /* height: 250px; */
  margin-bottom: 100px;
  margin-top: 20px;
  padding: 40px;
  text-align: left;
  /* border: 2px solid yellowgreen; */
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.checkoutNewLoginFormBox p {
  margin: 20px 0;
  font-size: 12px;
  font-weight: 600;
}
.checkoutNewLoginFormBox label {
  font-size: 13px;
}

.checkoutNewLoginFormBox input {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  font-size: 16px;
  height: 48px;
  padding: 8px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  position: relative;
}
.checkoutNewLoginFormBoxButton {
  width: 100%;
  height: 50px;
  margin: 20px 0;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.85;
  color: white;
  border: none;
  /* background-color: black; */
  background-color: #1a4c2d;
  cursor: pointer;
}
.checkoutNewLoginFormBoxButton:hover {
  opacity: 0.7;
}
.checkoutNewMainOuterBox {
  /* background-color: rgba(128, 128, 128, 0.1); */
  background-color: rgb(255, 250, 220, 0.5);
}
.checkoutNewMainOuterBox label {
  font-size: 14px;
}
.checkoutNewMainInnerBox {
  width: 100%;
  margin-top: 50px; /* height: 50vh; */
  display: grid;
  display: flex;

  grid-template-columns: 3fr 2fr;
  /* gap: 10px; */
  justify-items: center;
  align-content: center;
  /* flex-direction: column; */
  justify-content: center;
  align-items: flex-start;
}
.checkoutNewMainOuterBox h4 {
  font-size: 16px;
  color: black;
  font-weight: bolder;
}
.checkoutNewMainOuterBox label {
  font-size: 13px;
  font-weight: 400;
  margin-top: 20px;
}
.checkoutNewMainOuterBox input,
.checkoutNewMainOuterBox select {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  height: 48px;
  padding: 8px;
}

.checkoutNewMainOuterBox p {
  font-size: 13px;
  font-weight: 400;
}
.checkoutNewMainOuterLeftBox {
  width: 50%;
  display: flex;
  line-height: 0.8rem;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  height: 1000px;
  /* border: 2px solid red; */
  box-sizing: border-box;
}
.checkoutNewMainOuterRightBox {
  width: 25%;
  background-color: white;
  position: sticky;
  top: 200px;
  /* height: 500px; */
  /* border: 2px solid grey; */
  margin: 10px;
}
.checkoutNewMainDropdownButton {
  box-sizing: border-box;
  width: calc(100% - 20px); /* Subtract the total horizontal padding */
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 20px;
  margin: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.password-input {
  position: relative;
}
.invalid {
  border-color: red;
  color: red;
}
.valid {
  border-color: green;
  color: green;
}
.password-input input {
  padding-right: 40px; /* Adjust the padding to accommodate the button */
  border: 1px solid;
  /* border-color: none; */
  outline: none; /*need to check with other inputs*/
  transition: border-color 0.3s ease;
}

.password-input.invalid input {
  border-color: red;
}

.password-input.valid input {
  border-color: green;
}
.password-input.blank input {
  border: 1px solid rgba(128, 128, 128, 0.5);
}
.password-input button {
  position: absolute;
  right: 0;
  top: 0;
  /* bottom: 0; */
  /* height: 100%; */
  /* transform: translateY(-50%); */
  padding: 15px 20px;
  background-color: transparent;
  border: none;
  color: #1a4c2d;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* line-height: 1; */
}
.password-input > div {
  position: relative;
}
.password-requirements {
  display: flex;
}
.password-requirements p {
  margin-right: 10px;
  /* height: 20px; */
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}
.password-requirements :valid {
  color: green;
}
.checkOutNameMainBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}

.checkOutNameMainBox,
.checkOutInputBox {
  position: relative;
}

.checkMark {
  background-image: url("data:image/svg+xml,${iconString}");
  position: absolute;
  top: 50%;
  /* bottom: 25%; */
  right: 0.5rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: green;
}

.dobInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
}
.dobInputContainer input,
.dobInputContainer input:focus {
  border: none;
  outline: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.validBorder {
  border-color: green;
  border: 1px solid green;
}

.invalidBorder {
  border-color: red;
  border: 1px solid red;
}
.checkoutNewButtonBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.checkoutNewButtonBox button {
  width: 50%;
  height: 50px;
  font-size: 16px;
  color: white;
  border: none;
  opacity: 0.85;
  /* background-color: black; */
  background-color: #1a4c2d;
  cursor: pointer;
  opacity: 0.85;
}
.checkoutNewButtonBox button:hover {
  opacity: 0.7;
}
.checkOutNewMainEditOptionBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* checkoutPage Summary Card */

.checkOutPageNewSummaryCardMainBox {
  width: 100%;
  padding: 20px;
  padding-top: 50px;
  text-align: left;
  box-sizing: border-box;
  background-color: rgb(255, 250, 220, 0.1);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* border: 2px solid red; */
}
.checkOutPageNewSummaryCardInnerBox {
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(128, 128, 128, 0.5);
}
.checkOutPageNewProductCardMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  /* border: 2px solid yellow; */
  border-bottom: 2px solid rgba(128, 128, 128, 0.5);
}
.checkOutPageNewProductCardImageBox {
  width: 85px;
  height: 85px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.checkOutPageNewProductCardDetailsBox {
  padding: 10px;
}
.checkOutPageNewProductCardDetailsBox h5 {
  font-size: 14px;
}
.checkOutPageNewProductCardDetailsBox p {
  font-size: 11px;
}
.checkOutPageNewProductCardPriceBox {
  display: flex;
  align-self: flex-start;
  padding: 10px;
  /* border: 2px solid red; */
}
.checkOutPageNewProductCardOuterTotalBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px 0;
}
.checkOutPageNewProductCardOuterTotalBox p {
  margin: 5px 0;
}
.checkOutPageNewProductCardDiscountButton {
  margin-inline: 10px;
  padding-inline: 10px;
  background-color: rgba(128, 128, 128, 0.5);
  cursor: pointer;
}
#newToGhareLine > span {
  color: blue;
  /* color: rgba(241, 179, 29, 255); */
}
#newToGhareLine:hover > span {
  cursor: pointer;
  text-decoration: underline;
}
.passwordResetLoginAgainLink:hover {
  color: green;
  cursor: pointer;
  text-decoration: underline;
}

.customerSignUpBoxMain {
  padding-top: 30px;
}
.customerSignUpBoxMain p {
  margin: 10px 0;
}
.customerSignUpNameBox {
  display: grid;
  /* padding: 20px 0; */
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
/* .couponBoxFullPageMain {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  width: 10vw;
  height: 100%;
  background-color: #1a4c2d;
} */

.checkoutNewMainPaymentOptionsBox {
  width: 100%;
  /* border: 2px solid red; */
}
.checkoutNewMainPaymentOption {
  width: 50px;
  height: 35px;
}

@media (max-width: 650px) {
  .accountDetailsMainBox {
    margin: 0;
    padding: 0;
    font-size: smaller;
  }
  .checkoutMainContainer {
    flex-direction: column;
  }
  .orderSummaryCheckoutMain {
    width: fit-content;
  }
  .checkoutSecurelyBtn {
    width: 60vw;
  }
  .checkoutNewMainInnerBox {
    flex-direction: column;
  }
  .checkoutNewMainOuterLeftBox {
    width: 100%;
    margin-bottom: 0px;
    height: auto;
  }
  .checkoutNewMainOuterRightBox {
    position: relative;
    top: 0px;
    width: 95%;
  }
  .checkoutNewLoginFormBox {
    min-width: 250px;
    margin: 10px;
  }
}
