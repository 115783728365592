* {
  margin: 0;
  padding: 0;
}
.headingMainContainer {
  width: 100%;
  /* position: relative; */
  box-sizing: border-box;
}
.mobileIcons {
  display: none;
}
.subscribeNow {
  margin-top: 0;
  padding: 0.3rem;
  background-color: #1a4c2d;
  color: white;
  /* width: "100%"; */
  font-size: 0.65rem;
  /* font-family: "Red Hat Display", sans-serif; */
}
#subscribeNowLink:hover {
  color: orange;
  cursor: pointer;
  transition: 0.5s;
}
.coming-soon::before {
  content: "Coming Soon";
  display: none;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  background: #000;
  color: #fff;
  padding: 5px;
  z-index: 5;
  font-size: 12px;
  border-radius: 5px;
}

.coming-soon:hover::before {
  display: block;
}
.logoBar {
  /* padding: 0.5rem; */
  /* justify-content: space-around; */
  /* padding: 0.5rem; */
  /* background-color: rgba(251, 225, 183, 255); */
  background-color: rgb(255, 250, 220);
  /* border: 2px solid green; */
  position: relative;
  box-shadow: inset 0px -5px 10px -10px rgba(0, 0, 0, 0.5);
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* background-color: rgba(251, 225, 183, 255); */
  /* beige */
  /* background-color: rgba(26, 76, 44, 255); */
  /* Dark Green; */
  /* background-color: rgba(241, 177, 29, 1); */
  /* Dark Yellow */
  /* background: radial-gradient(
    rgba(241, 179, 29, 255),
    rgba(251, 225, 183, 255)
  ); */
  /* background: radial-gradient(
      ellipse at top,
      rgba(241, 179, 29, 255),
      transparent
    ),
    radial-gradient(ellipse at bottom, rgba(251, 225, 183, 255), transparent); */
}
#mainLogo {
  /* width: 32.5%; */
  /* width: 50vw; */
  height: 12vh;
  /* width: 20vh; */
  /* margin-left: 50%; */
  /* background-repeat: no-repeat;
  background-size: contain; */
  /* background-color: rgba(251, 225, 183, 1); */
  /* background-color: rgb(255, 250, 220); */
}
#mainLogo:hover {
  cursor: pointer;
}

.hamburger {
  display: none;
  position: absolute;
  width: 50px;
  height: 50px;
  /* background-color: green; */
  left: -2%;
  top: 22%;
  align-items: center;
  padding: 0.5rem;
}

.hide {
  display: none;
}
.drawerScreenMain {
  width: 75vw;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  border: 2px solid pink;
  background-color: #1a4c2d;
}

.drawerScreen {
  position: fixed;
  display: block;

  width: 25%;
  height: 100%;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.666);
}
.mobileMenuList {
  width: 80vw;
  height: 100%;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  position: fixed;
  box-sizing: border-box;
  /* background-color: rgb(255, 250, 220); */
  /* margin: 2rem; */
  /* background-image: radial-gradient(white, grey); */
  /* background-color: #1a4c2d; */
  background-color: white;
  text-align: left;
  overflow-y: scroll;
}
.closeBtn {
  position: absolute;
  right: 0;
  z-index: 2;
  padding: 0.5rem;
  margin: 0.2rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.836);
}
.mobileNavbarBottom a button {
  margin: 0rem;
  padding: 0.5rem;
}
@media (max-width: 650px) {
  .logoBar {
    width: 100%;
    /* padding: 0 10px; */
    display: flex;
    justify-content: left;
    align-items: center;
    /* border: 2px solid red; */
  }
  #mainLogo {
    /* width: 70%; */
    width: 150px;
    height: fit-content;
    height: 50px;
    margin-left: 40px;
    margin-right: 0px;
    /* margin: 0 20vw; */
    /* margin: 0; */
    margin-top: 0.75rem;
    /* border: 2px solid red; */
  }
  .mobileIcons {
    display: flex;
    margin: 0;
    margin-left: 0rem;
    width: 350px;
    height: 50px;
    display: flex;
    align-items: center;
    font-size: small;
    justify-content: space-evenly;
    /* border: 2px solid red; */
  }
  .navbar {
    display: none;
  }
  .hamburger {
    display: inline-block;
  }
  .hamburgerIcon {
    margin-top: 0.25rem;
  }
  #handBagIconMobile {
    margin-right: 20px;
    position: relative;
  }
  .mobileNavbarTopOffer {
    background-image: url("../Images/featuredImge.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media (max-width: 285px) {
  .drawerScreen {
    width: 45vw;
  }
}
