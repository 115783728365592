.navbar {
  display: flex;
  width: 100%;
  justify-content: center;
  /* font-family: "Red Hat Display", sans-serif; */
  /* font-family: Monsterrat Light; */
  /* padding: 1rem; */
  /* font-size: 0.9rem; */
  font-size: 14px;
  /* background-color: rgba(241, 179, 29, 255); */
  /* background-color: rgba(251, 225, 183, 255); */
  /* background-color: rgb(255, 250, 220); */
  background-color: white;
  box-sizing: border-box;
  /* color: rgba(26, 76, 44, 1); */
  font-weight: 400;
  /* border: 2px solid red; */
  /* border-top: 2px solid rgba(241, 179, 29, 0.8); */
  /* border-top: 2px solid peru; */
  /* border-bottom: 2px solid rgba(241, 179, 29, 0.8); */
  /* border-bottom: 2px solid peru; */
  /* z-index: 4; */
  /* box-shadow: 0px 5px 6px -6px #888, 0px -5px 6px -6px #888; */
  /* box-shadow: inset 0px -8px 6px -6px #888; */
  /* border-top: 2px solid rgba(26, 76, 44, 255); */
  /* border-bottom: 2px solid rgba(26, 76, 44, 255); */
}
.navbarMain {
  box-shadow: 0px 5px 10px -8px rgba(0, 0, 0, 0.5);
  /* box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.5),
    0px -5px 5px -5px rgba(0, 0, 0, 0.5); */
  /* box-shadow: 0px 1px 7px #888; */

  /* box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.5); */
}
.navbar a:hover {
  /* color: #1a4c2d; */
  color: peru;
}
.searchAndStoreBox {
  /* width: 20vw; */
  /* border: 2px solid green; */
  width: 20%;
  display: flex;
  /* border: 2px solid orange; */
  justify-content: center;
  align-items: center;
}
.searchAndStoreItem {
  /* margin-left: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 1.5rem;
  position: relative;
}
.searchAndStoreItem a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.navSearchBar {
  position: absolute;
  width: 20rem;
  height: 5rem;
  top: 0;
  left: 0;
  margin-top: 1.8rem;
  margin-left: 1rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navSearchBar input {
  padding: 0.2rem;
}
.links {
  width: 45%;
  padding-left: 9.5rem;
  /* border: 2  px solid green; */
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  list-style: none;
  /* border: 2px solid yellow; */
}

.link {
  padding: 1rem 1.2rem;

  cursor: pointer;
}
.link a {
  text-decoration: none;
  color: black;
  /* color: rgba(241, 179, 29, 255); */
  /* color: rgba(26, 76, 44, 255); */
}
.favAndBag {
  width: 30%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  /* border: 2px solid green; */
}
.fav,
.signIn,
.bag {
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.searchBtn:hover {
  color: peru;
}
.searchBarBoxMain {
  position: fixed;
  width: 100vw;
  height: 100vh;
  right: 0;
  bottom: 0;

  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
}
.searchBarBoxMain input {
  width: 30%;
  height: 2rem;
  font-size: 24px;
  padding: 0.5rem;
}
.searchBarBoxCloseBtn {
  position: fixed;
  right: 5rem;
  top: 5rem;
  z-index: 20;
  cursor: pointer;
  color: white;
}
.searchBarBoxCloseBtn:hover {
  color: peru;
}
.searchBarBoxMain button {
  width: 30%;
  margin: 2rem 0;
  height: 3rem;
  font-size: 24px;

  color: wheat;
  background-color: #1a4c2d;
  background: linear-gradient(
    90deg,
    rgba(3, 127, 0, 1) 0%,
    rgba(0, 95, 14, 1) 54%,
    rgba(0, 16, 9, 1) 100%
  );
  border-radius: 5px;
}
.searchBarBoxMain button:hover {
  color: white;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;

  background-color: #006c2a;

  background: linear-gradient(
    90deg,
    rgba(83, 242, 79, 1) 0%,
    rgba(0, 181, 26, 1) 55%,
    rgba(2, 98, 1, 1) 100%
  );
  /* border: 2px solid wheat;    */
}
/* Dropdown Button */
.dropbtn {
  /* background-color: rgb(255, 250, 220); */
  /* background-color: rgba(251, 225, 183, 255); */
  /* color: black; */
  border: 2px solid red;
  /* color: rgba(26, 76, 44, 255); */
  cursor: pointer;

  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 3;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  left: -12rem;
  /* top: 3.5rem; */
  top: 80%;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* font-family: "Red Hat Display", sans-serif; */
}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  box-sizing: border-box;
  margin: 0.5rem;
  text-decoration: none;
  width: 35%;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  text-decoration: underline;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: flex;
  animation-name: navbarHoverAnimation;
  animation-duration: 100ms;
  animation-iteration-count: unset;
}
@keyframes navbarHoverAnimation {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0px);
  }
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  cursor: pointer;
}
.dropdown-box {
  display: flex;
  margin: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}
.dropdown-box h2,
h3 {
  color: rgba(26, 76, 44, 255);
}
.firstBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 30vh;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.dropdown-box h3 {
  width: 25vh;
  text-align: start;
  margin: 0.5rem 0.5rem;
  display: block;
}
.allCategoryItems {
  text-align: start;
  display: flex;
  flex-wrap: wrap;
}
.allCategoryItems li {
  margin: 1rem;
}
.allCategoryItems a {
  margin: 0rem;
  /* margin: 1rem; */
}
.otherBullet {
  list-style: circle;
}
.ourCollections {
  margin-left: 1rem;
  text-align: start;
  width: 30vh;
}
.ourCollections p {
  margin-top: 1.5rem;
  margin: 0.5rem;
}
.featured {
  padding: 0rem 1rem;
  text-align: start;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
}
.featured h2 {
  margin: 0.5rem 0;
}
.featuredProductImage {
  width: 35vh;
  height: 35vh;
  position: relative;
  /* background-image: url(); */
  background-image: url("../Images/featuredImge.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
.featuredProductOffer {
  position: absolute;
  bottom: 0;
  left: 0;
}
.featuredProductImage h2,
h1 {
  margin: 0.5rem 1rem;
  color: white;
  font-weight: bold;
}
.shopNowBtn {
  padding: 0.5rem 1.2rem;
  margin: 1rem;
  margin-top: 0rem;
  cursor: pointer;
  position: relative;
  transition: color 300ms ease-in-out;
  z-index: 1;
}
.shopNowBtn::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: gray;
  z-index: -1;
  transform: scaleX(0);
  transition: transform 500ms ease-in-out;
}
.shopNowBtn:hover::after {
  transform: scaleX(1);
}
.shopNowBtn:hover {
  color: white;
  font-weight: bold;
}
.myBagMainBox {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.myBagProducts {
  flex-direction: row;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.dropdown2 {
  position: relative;
  display: inline-block;

  z-index: 3;
}
.dropdown2:hover .dropdown-content2 {
  display: flex;
  top: 80%;
  animation-name: myBagAnimation;
  animation-duration: 300ms;
  animation-iteration-count: unset;
  transform-origin: top right;
}
@keyframes myBagAnimation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.dropdown2:hover .dropbtn2 {
  cursor: pointer;
}
.dropdown-content2 {
  display: none;
  position: absolute;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* font-family: "Red Hat Display", sans-serif; */
}
.bagIcon {
  position: relative;
}
.myBagProductImage {
  width: 5rem;
  height: 5rem;
  margin-bottom: 0.5rem;
}
.myBagProductDetails p {
  text-align: start;
  font-weight: normal;
  margin: 1rem 1rem;
  width: 10rem;
}
.myBagTotalBox {
  margin: 0 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.myBagTotalBox span {
  font-weight: bold;
  font-size: large;
}
.myBagBtns {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  margin-top: 1rem;
}
.myBagBtns button {
  padding: 0.5rem 1.5rem;
  position: relative;
  z-index: 1;
  margin: 0.5rem;
  margin-bottom: 0rem;
  font-size: medium;
  transition: color 300ms ease-in-out;
  color: white;
  border-radius: 0.3rem;
  cursor: pointer;
  /* font-family: "Red Hat Display", sans-serif; */
}
.myBagBtns button:hover {
  color: grey;
}
#viewCartBtn {
  background-color: grey;
  border-color: grey;
}
#checkoutBtn {
  background-color: #1a4c2d;
  border-color: #1a4c2d;
}
.myBagBtns button::after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  position: absolute;
  z-index: -1;
  background-color: white;
  transform: scaleX(0);
  border-radius: 0.4rem;
  transition: transform 300ms ease-in-out;
}
.myBagBtns button:hover::after {
  transform: scaleX(1);
}
.bagTotalCount {
  position: absolute;
  display: flex;
  justify-content: center;
  font-weight: 700;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  top: -50%;
  left: -15%;
  border-radius: 1.2rem;
  background-color: rgba(241, 179, 29, 255);
}
.search {
  margin-top: 0rem;
  left: -50%;
  padding: 0.5rem;
}
.search input {
  height: 2rem;
  padding: 0 0.5rem;
  color: peru;
  border-color: peru;
}
.search {
  display: flex;
  align-items: center;
}
@media (max-width: 650px) {
  /* #mainLogo {
    width: 50vw;
    margin-top: 0.75rem;
    margin-left: 2.5rem;
    border: 2px solid red;
    display: flex;
  }
  .mobileIcons {
    display: flex;
    width: 10vw;
    border: 2px solid red;
  } */
  .navbar {
    display: none;
  }
  /* .hamburger {
    display: inline-block;
  }
  .hamburgerIcon {
    margin-top: 0.25rem;
  } */
}
