.navbarMainBox {
  width: 100%;
  height: 9vh;
  /* background-color: rgba(0, 0, 0, 0.727); */
  background-color: #38414a;
  color: #343a40;
  position: fixed;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  justify-content: space-between;
  padding-left: 2rem;
  align-items: center;
}
.logoBox {
  padding: 1rem 1rem;
  /* padding-left: 1rem; */
  margin-left: 1rem;
  width: 16rem;
  height: 3rem;
  background-image: url("../../../Images/MainLogo1.png");
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
}
.adminNavbarIcons {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.adminNavbarIcons:hover {
  color: white;
}
.searchBox {
  margin-left: 55vw;
  position: relative;
  box-sizing: border-box;
}
.searchBox input {
  width: 15rem;
  height: 2rem;
  border-radius: 1rem;
  /* border-color: white; */
  background-color: rgba(255, 255, 255, 0.339);
  /* color: white; */
  padding: 0 0.5rem;
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.642);
}
.searchBox input:focus {
  outline: none;
}
.searchIcon {
  position: absolute;
  /* background-color: white; */
  width: 3rem;
  height: 2rem;
  right: 0.5rem;
  bottom: 0;
  top: 5px;
  border-radius: 1rem;
}
.signOutBox {
  margin-left: 1rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  /* border: 2px solid red; */
  position: relative;
  cursor: pointer;
}
.profileDetailsBox {
  width: 100%;
  /* border: 2px solid white; */
  height: 100%;
  margin-left: 0.5rem;
  color: rgb(255, 255, 255, 0.6);
}
.profileDetailsBox:hover {
  color: rgb(255, 255, 255, 0.9);
}
.profileDropdownBox {
  position: absolute;
  margin-top: 1rem;
  width: 150%;
  /* height: 15rem; */
  right: 0;
  top: 100%;
  /* background-color: rgba(169, 169, 169, 0.9); */
  background-color: white;
  border: 1px solid rgb(0, 0, 0);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: gray;
}
.none {
  display: none;
}
.profileDropdownBox h6 {
  margin: 1rem;
  /* color: white; */
}
.profileDropdownBox h5 {
  /* color: white; */
  text-align: left;
  padding: 0.5rem 1rem;
}
.profileDropdownBox h5:hover {
  color: #00acc1;
}
.adminSettingsDrawer {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-out;
}
.adminSettingsDrawer2 {
  position: fixed;
  top: 0;
  /* left: -300px; */
  left: -300px;
  width: 15%;
  height: 100%;
  z-index: 4;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease-out;
}

.adminDrawerOpen {
  right: 0px;
}
.adminDrawerOpen2 {
  left: 0px;
}
.adminDrawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}
.adminDrawerOverlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}
.adminDrawerMainIconsBox {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  border-bottom: 2px solid grey;
  padding-bottom: 1.5rem;
}
.adminDrawerIcons:hover {
  cursor: pointer;
  color: #00acc1;
}
.adminDrawerChatsMainBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.adminDrawerChatsMainBox button {
  color: gray;
  padding: 5px 10px;
  margin-top: 2rem;
  cursor: pointer;
}
.adminDrawerChatsInput {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 1.5rem 0;
  margin-bottom: 0.5rem;
  width: 200px;
  border: 1px solid grey;
  /* height: 30px; */
  /* padding: 0.5rem 1 rem; */
  border-radius: 30px;
}
.adminDrawerChatsInput input {
  border: none;
  font-size: 14px;
  font-weight: 200;
  padding: 5px;
  width: 140px;
  margin: 2px;
}
.adminDrawerChatsInput input:focus {
  outline: none;
}
.adminDrawerAllChatsBox {
  text-align: left;
  width: 100%;
}
.adminDrawerAllChatsBox p,
.adminDrawerAllChatsBox li {
  margin: 1rem 2rem;
}
.adminDrawerAllChatsBoxHeading {
  font-weight: bolder;
  font-size: 12px;
}
.adminDrawerAllChatsBoxContent {
  font-size: 12px;
  padding-left: 2rem;
  list-style-type: circle;
}
.adminDrawerTasksMainBox {
  text-align: left;
  padding: 2rem;
}
.adminDrawerTasksButtonBox {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
.adminDrawerTasksButtonBox button {
  padding: 0.5rem 1rem;
  width: 85%;
  background-color: #00acc1;
  border-color: transparent;
  border-radius: 8px;
  color: white;
}
.adminDrawerNoteBox {
  /* width: 80%; */
  display: flex;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  font-size: 14px;
  word-spacing: 0.3rem;
  font-weight: 400;
  background-color: rgba(255, 166, 0, 0.3);
}

/* switch cases */

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 20px;
  margin: 0px 5px;
}

.switch.light .checkbox:checked + .slider {
  background-color: #2196f3;
}

.switch.light .checkbox:checked + .slider:before {
  transform: translateX(26px);
}

.switch.light .slider:before {
  background-color: #ffffff;
}

.switch.light .slider:after {
  background-color: #ffffff;
}

.switch.dark .checkbox:checked + .slider {
  background-color: #2196f3;
}

.switch.dark .checkbox:checked + .slider:before {
  transform: translateX(26px);
}

.switch.dark .slider:before {
  background-color: #ffffff;
}

.switch.dark .slider:after {
  background-color: #ffffff;
}

.checkbox {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 0.2s ease;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 4px;
  background-color: #ffffff;
  transition: transform 0.2s ease;
  border-radius: 50%;
}

.slider:after {
  position: absolute;
  content: "";
  font-size: 14px;
  color: #757575;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  transition: color 0.2s ease;
}

.slider.dark:after {
  content: "";
}
.radioBtns {
  display: flex;
  padding: 0;
  align-items: center;
}
.radioBtns label {
  font-weight: normal;
}
.adminSidebarOpenIcon {
  position: relative;
  left: 7vw;
  margin-left: 3rem;
  padding: 1rem;
  padding-left: 5rem;
  color: rgba(255, 255, 255, 0.632);
  /* top: 0; */
  z-index: 2;
  cursor: pointer;
}
.adminDrawerIconsOpen {
  /* border: 2px solid yellow; */
  left: -15%;
  /* left: 0; */
}
