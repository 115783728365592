.uspPointsMainBox {
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  font-size: 20px;
  /* height: 20rem; */
  /* border: 2px solid red; */
  /* background-image: url("https://cdn.pixabay.com/photo/2015/10/15/21/09/texture-990071_960_720.jpg"); */
  /* background-image: url("https://cdn.pixabay.com/photo/2021/03/15/19/08/grunge-6097778_960_720.jpg");
  background-image: url("https://cdn.pixabay.com/photo/2015/10/15/21/05/texture-990067_960_720.jpg"); */
  /* background-image: url("https://cdn.pixabay.com/photo/2015/03/02/21/20/pattern-656585_960_720.jpg  "); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #1a4c2d;
  color: goldenrod;
  /* box-shadow: 0px 2px 5px black; */
  position: relative;
  text-align: left;
  padding: 1rem;
  z-index: 2;
  /* padding: 2rem; */
  /* border-bottom: 5px solid rgb(0, 0, 0); */
}

.grid-item {
  /* margin: 1rem 5rem; */
  display: flex;
  padding: 0.5rem;
  padding-left: 5rem;
  /* border: 2px solid rgba(0, 0, 0, 0.523); */
  flex-wrap: wrap;
  align-items: center;
  /* box-shadow: 2px 2px 5px black; */
  /* opacity: 0.7; */
  /* border: 2px solid rgba(0, 0, 0, 0.51); */
  border-radius: 0.5rem;
}
/* .grid-item h5 { */
/* padding: 1rem; */
/* box-shadow: inset 10px 10px 5px black; */
/* border: 2px solid rgba(0, 0, 0, 0.51); */
/* } */
.uspIcons {
  padding: 0rem;
  border-radius: 50%;
  /* box-shadow: inset 10px 10px 5px black; */
  /* border: 1px solid rgba(0, 0, 0, 0.51); */
}
/* .uspIcons:hover,
.grid-item h5:hover {
  transform: scale(1.1);
} */

@media (max-width: 650px) {
  .grid-item {
    padding-left: 1rem;
    padding-left: 5px;
    padding: 0;
    padding: 0px;

    width: 80%;
    margin: 5px;
  }
  .uspPointsMainBox {
    grid-gap: 5px;
    font-size: 10px;
    width: 100%;
    /* height: 600px; */
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    /* height: 300px; */
    padding: 10px;
    grid-gap: 0;
    /* align-items: center; */
  }
  .uspIcons {
    width: 20px;
    border-radius: 20%;
    height: 20px;
    margin: 0px 20px;
    padding: 5px;
    margin: 5px 0;
  }
}

@media (max-width: 300px) {
  .uspPointsMainBox {
    grid-template-columns: repeat(2, 1fr);
  }
}
