.ourContactMainBox h1 {
  color: black;
  margin: 3rem;
  font-size: 42px;
  margin-bottom: 1rem;
}
.ourContactMainBox p {
  font-size: 17px;
  color: grey;
}
.sectionContactHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}
.OurContactMainContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 2rem;
  margin-top: 3rem;
}
.ourContactItems {
  width: 20vw;
  border: 1px solid rgba(128, 128, 128, 0.4);
  padding: 2rem;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  /* border-radius: 5px; */
  text-align: left;
}
.ourContactItems h4 {
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.7);
}
.ourContactItems p {
  font-size: 17px;
  word-spacing: 2px;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.4);
}
.ourContactItems a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.4);
}
.form-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, auto);
  grid-gap: 2rem;
  margin-top: 3rem;
  text-align: left;
}

.form-item {
  grid-column: span 1;
  grid-row: auto;
}
.form-item label {
  color: rgba(0, 0, 0, 0.7);
  font-size: 24px;
}
.form-item input {
  width: 20vw;
  padding: 0.7rem;
  font-size: 16px;
}
.form-item textarea {
  width: 100%;
  margin-left: 0;
}
.form-item--full {
  grid-column: 1 / span 3;
  grid-row: auto;
  box-sizing: border-box;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
.ourContactBtn {
  font-size: 18px;
  margin: 2rem 0;
}
