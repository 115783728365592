.aboutUsMainBox {
  margin-bottom: 2rem;
}

.aboutUsMainBox h1 {
  /* font-family: "Red Hat Display, sans-serif"; */
  font-size: 46px;
  margin: 1.5rem;
  margin-top: 3rem;
}
.aboutUsMainBox h2 {
  /* font-family: "Red Hat Display, sans-serif"; */
  font-size: 34px;
  padding: 0.5rem;
  margin-top: 12rem;
}
.aboutUsMainBox h3 {
  /* font-family: "Red Hat Display, sans-serif"; */
  font-size: 20px;
  /* word-spacing: 2px; */
  /* line-height: 20px; */
  /* text-transform: uppercase; */
}
.bannerBox {
  /* height: 20vw; */
  width: 100%;
  object-fit: cover;
  object-position: 20% 67%;
  margin-bottom: 3rem;
}
.aboutUsStoryMain {
  /* width: 100vw; */
  /* height: 50vh; */
  /* margin: 2rem 0; */
  /* margin-bottom: 0; */
  /* border: 2px solid red; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.reverse {
  display: flex;
  flex-direction: row-reverse;
}
.aboutUsStoryPhotoBox {
  width: 22.5rem;
  height: 22.5rem;
  /* border: 2px solid gray; */
  display: flex;
  justify-content: center;
  position: relative;
}
.photo1 {
  width: 15rem;
  height: 15rem;
  top: 0%;
  left: 0%;
  /* border: 2px solid yellow; */
  position: absolute;
}
.photo2 {
  width: 15rem;
  height: 15rem;
  top: 50%;
  left: 50%;
  position: absolute;
  /* border: 2px solid orange; */
}
.aboutUsStoryDescriptionBox {
  width: 60%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid green; */
}
.aboutUsStoryDescriptionBox p {
  text-transform: uppercase;
  font-size: 14px;
  word-spacing: 7px;
  line-height: 25px;
}
.divider {
  margin: 2rem;
  width: 50vw;
}
.brandStandBox {
  display: flex;
  margin: 10px 100px;
  /* padding-bottom: 3rem; */
  justify-content: space-around;
  border-bottom: 5px solid gold;
}
.brandStandImage {
  width: 30%;
  height: 30%;
  margin: 2rem 0;
}
.ourInspirationBox {
  margin-top: 10rem;
}
.ourInspirationBox h1 {
  text-decoration: underline;
  text-decoration-color: gold;
  text-underline-position: below;
}
.ourInspirationBox h3 {
  margin: 5rem 0;
  padding: 0 20%;
}
.ourInspirationPara {
  margin: 5rem;
  padding: 0.5rem;
  position: relative;
}
.cornerBorders {
  width: 12rem;
  position: absolute;
}
.border1 {
  top: -2rem;
  left: 10rem;
}
.border2 {
  bottom: -2rem;
  right: 10rem;
}
.ourBeliefBox {
  padding: 5rem;
  text-align: left;
}
.quoteSymbolBox {
  background-color: rgb(255, 250, 220);
  width: 6rem;
  height: 7rem;
  color: black;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.ourBeliefBox h2 {
  font-weight: lighter;
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.7);
  width: 50vw;
  border-bottom: 5px solid gold;
  padding-bottom: 5rem;
}
.aboutUsWhyUsBox1 {
  width: 100%;
  padding: 0 5rem;
  background-color: rgb(255, 250, 220);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.aboutUsWhyUsBox1Items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-size: larger; */
  /* margin: 1rem 0; */
  padding: 1rem;
  border-right: 1px solid grey;
}
.aboutUsWhyUsBox1Icons,
.aboutUsWhyUsBox1Items p {
  color: gray;
  margin: 1.5rem;
  font-size: medium;
  font-weight: bold;
}
.aboutUsWhyUsBox2 {
  display: grid;
  grid-template-columns: repeat(auto-fill);
  padding: 2rem 2rem;
  box-sizing: border-box;
}
.aboutUsWhyUsBox2 h3 {
  font-size: large;
  color: white;
  background-color: rgba(128, 128, 128, 0.8);
  /* background-color: rgba(176, 224, 230, 0.341); */
  /* background-color: rgb(255, 250, 220); */
  padding: 1rem;
  margin: 0.5rem;
}
.lastWhyUsGridItem {
  grid-column: 2 / span 3;
}
.affiliatesAndAwardsMainContainer {
  margin-bottom: 10rem;
}
.affiliatesAndAwardsMainContainer h1 {
  padding-bottom: 1rem;
  text-decoration: underline;
  text-decoration-color: gold;
  box-sizing: border-box;
}
.affiliatesLogoBoxMain {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
}
.affiliatesLogoBox {
  /* width: 20%; */
  /* aspect-ratio: 1; */
  display: flex;
  /* padding: 1rem; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 3px solid gold;
}
.affiliatesLogo {
  width: 40%;
  /* height: 60%; */
  padding: 1rem 0;
}
.affiliatesLogoBox h3 {
  font-size: 1.4rem;
}
.awardsMainBox {
  display: grid;
  padding: 1rem;
  row-gap: 1rem;
  column-gap: 1fr;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  margin-bottom: 1rem;
}
.awards {
  width: 23vw;
  aspect-ratio: 1;
}

/* new Design */

.aboutUSGoldenStoryHeading {
  width: 100%;
  /* border: 2px solid yellow; */
  position: relative;
  margin-bottom: 30px;
  /* margin-top: 2px; */
  overflow: hidden;
  box-shadow: 0px 10px 10px grey;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

.aboutUsGoldenStoryBorderLeft {
  position: absolute;
  left: 0;
  bottom: 0;
}
.aboutUsGoldenStoryBorderRight {
  position: absolute;
  right: 0;
  top: 0;
}
.aboutUsGoldenStoryLogo {
  height: 250px;
  margin: 20px 0;
}
.aboutUsMainBox h3 {
  color: rgba(0, 0, 0, 0.7);
  line-height: 35px;
  margin: 0px 100px;
  text-align: left;
}
.aboutUsSecondParaBox {
  width: 100%;
  display: flex;
  overflow: hidden;
  /* border: 2px solid red; */
  box-sizing: border-box;
}
.aboutUsSecondParaLeft {
  width: 60%;
  /* border: 2px solid orange; */
}
/* .aboutUsSecondParaRight {
 
 
} */
.aboutUsSecondParaImage {
  width: auto;
  /* border: 2px solid green; */
}
.aboutUsBrandStandMainBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}
.aboutUsBrandStandMainBox h1 {
  margin-bottom: 0px;
  color: #1a4c2d;
  /* border: 2px solid orange; */
}
.aboutUsParaBorder {
  height: 60px;
  width: 30%;
  /* border: 2px solid yellow; */
}
.aboutUsBrandStandValuesBox {
  width: 85%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* border: 2px solid yellow; */
  padding: 50px;
  gap: 20px;
}
.aboutUsBrandStandValuesBox h2 {
  margin: 0;
  padding: 20px;
  color: #1a4c2d;
  background-color: rgba(241, 179, 29, 255);
}
.aboutUsBrandStandValuesBox h2:hover {
  cursor: pointer;
  background-color: rgba(54, 141, 32, 0.911);
  color: white;
  font-weight: bolder;
}
#section3 {
  align-self: "start";
  padding-inline: "100px";
}
.ourInspirationOuterBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}
.ourInspirationMainBox {
  width: 85%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #1a4c2d;
}
.ourInspirationMainBoxTop {
  border-top-right-radius: 100px;
}
.ourInspirationMainBoxBottom {
  border-bottom-left-radius: 100px;
}
.ourInspirationMainBox h1 {
  padding: 0;
  margin: 0;
  color: white;
}
.ourInspirationMainBox h3 {
  color: white;
}
.aboutUsWhyShopMainBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutUsWhyShopMainBox h1 {
  color: #1a4c2d;
  margin-bottom: 50px;
}
.aboutUsWhyShopIconsMainBox {
  width: 85%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}
.aboutUsWhyShopIconsBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 200px; */
  word-spacing: 3px;
  padding: 20px;
  /* height: 50px; */
  color: #1a4c2d;
  background-color: rgba(241, 179, 29, 255);
}
.aboutUsWhyShopIcon {
  font-size: 3rem;
}
.aboutUsWhyShopIconsBox h4 {
  margin: 10px 0;
  font-weight: bolder;
}
.aboutUsWhyShopIconsBox:hover {
  cursor: pointer;
  background-color: rgba(54, 141, 32, 0.911);
  font-weight: bolder;
  color: white;
}
.aboutUsAffiliatesMainBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutUsAffiliatesLogoBoxMain {
  margin-top: 30px;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.aboutUsAffiliatesLogoBox {
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* height: 500px; */
  /* justify-content: center; */
  align-items: center;
  box-shadow: 0px 10px 20px grey;
}
.aboutUsAffiliatesLogo {
  width: 15rem;
}
.aboutUsAffiliatesLogoBox h2 {
  margin: 10px;
  margin-inline: 50px;
  font-size: 26px;
  margin-bottom: 60px;
}
.aboutUsAffiliatesLogoBox:hover {
  cursor: pointer;
  background-color: rgba(54, 141, 32, 0.911);
  color: white;
}
.aboutUsOurTeamMemberMainBox {
  width: 100%;
}
.aboutUsTeamMemberCarousalMainBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mySwiperOuter {
  width: 90vw;
  position: relative;
  z-index: 1;
}
.mySwiper3 {
  width: 80vw;
  /* height: 400px; */
  box-sizing: border-box;
  position: relative;
  box-shadow: -10px 0px 10px -10px rgba(0, 0, 0, 0.5),
    10px 0px 10px -10px rgba(0, 0, 0, 0.5);
}
.aboutUsTeamImageSlide {
  padding: 20px 0;
}
.aboutUsTeamImage {
  width: 200px;
  height: 300px;
  margin: 10px;
}

.mySwiperOuter .swiper-button-prev,
.mySwiperOuter .swiper-button-next {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: rgba(241, 179, 29, 255);
  color: #1a4c2d;
  font-size: 20px;
  padding: 5px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}
@media (max-width: 550px) {
  .aboutUsMainBox h1 {
    font-size: xx-large;
  }
  .aboutUsMainBox h2 {
    font-size: x-large;
    margin-top: 0;
  }
  .aboutUsMainBox h3 {
    font-size: medium;
  }
  .brandStandBox {
    margin: 1.5rem;
  }
  .brandStandImage h2 {
    font-size: 16px;
  }
  .divider {
    width: 75vw;
  }
  .aboutUsStoryDescriptionBox p {
    font-size: medium;
  }
  .aboutUsStoryPhotoBox {
    width: 12rem;
    height: 12rem;
  }
  .photo1,
  .photo2 {
    width: 8rem;
    height: 8rem;
  }
  .aboutUsWhyUsBox1 {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .aboutUsWhyUsBox1Items,
  .aboutUsWhyUsBox1Icons,
  .aboutUsWhyUsBox1Items p {
    margin: 0px;
    padding: 0;
    font-size: x-small;
  }
  .aboutUsWhyUsBox1Items {
    padding: 10px;
  }
  .aboutUsWhyUsBox2 {
    padding: 1rem 0.2rem;
  }
  .aboutUsWhyUsBox2 h3 {
    font-size: xx-small;
    padding: 0.5rem;
  }
  .affiliatesLogoBox h3 {
    font-size: 14px;
  }
  .aboutUsGoldenStoryBorderRight,
  .aboutUsGoldenStoryBorderLeft {
    width: 30%;
  }
  .aboutUsGoldenStoryLogo {
    width: 50%;
    height: 50%;
  }
  .aboutUsMainBox h3 {
    line-height: 20px;
    margin: 10px;
    margin-top: 0;
    font-size: smaller;
  }
  .aboutUsSecondParaBox {
    display: flex;
    flex-direction: column-reverse;
  }
  .aboutUsSecondParaLeft {
    width: 100%;
  }
  .aboutUsSecondParaImage {
    width: 60%;
    margin-bottom: 0px;
    margin-top: 20px;
  }
  .aboutUsBrandStandMainBox h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .aboutUsParaBorder {
    width: 60%;
    height: 35px;
  }
  .aboutUsBrandStandValuesBox {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  }
  .aboutUsBrandStandValuesBox h2 {
    padding: 5px;
    font-size: small;
  }
  .aboutUsWhyShopIconsBox {
    margin: 0;
    padding: 5px;
    font-size: x-small;
  }
  .aboutUsWhyShopIconsMainBox {
    width: 95%;
    gap: 5px;
    word-spacing: normal;
  }
  .aboutUsWhyShopIcon {
    font-size: 1rem;
  }
  .aboutUsAffiliatesLogoBoxMain,
  .aboutUsAffiliatesLogoBox {
    width: 90%;
    margin: 0;
    padding: 10px 5px;
    gap: 10px;
  }
  .aboutUsAffiliatesLogoBox {
    gap: 10px;
  }
  .aboutUsAffiliatesLogo {
    width: 70px;
  }
  .aboutUsAffiliatesLogoBox h2 {
    margin: 0;
    margin-bottom: 20px;
    padding: 0;
    font-size: xx-small;
  }
  .aboutUsTeamImage {
    width: 100px;
    height: 100px;
  }
  .mySwiperOuter .swiper-button-prev,
  .mySwiperOuter .swiper-button-next {
    font-size: 14px;
    padding: 2px;
    font-weight: 100;
    height: 5px;
    width: 5px;
  }
  .mySwiperOuter {
    width: 100vw;
  }
}

/* @media (max-width: 950px) {
  .cornerBorders {
    width: 10rem;
  }
  .border1 {
    top: 0;
    left: 0;
  }
  .border2 {
    bottom: 0;
    right: 0;
  }
} */
