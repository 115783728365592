.footerOuterContainer {
  width: 100%;
  box-sizing: border-box;
}
.footerOuterContainer a {
  text-decoration: none;
}
.footerMain {
  background-color: #1a4c2d;
  width: 100%;
  /* padding-left: 5rem; */
  /* padding: 0 5rem; */
  padding-top: 5rem;
  display: flex;
  justify-content: space-evenly;
  /* flex-wrap: wrap;  */
  box-sizing: border-box;
}
.footerItems {
  width: 18vw;
  height: 100%;
  text-align: start;
  margin: 1rem;
  margin-bottom: 0;
}
.footerItems h3 {
  color: white;
  /* font-size: 1.15rem; */
  margin-bottom: 2rem;
  font-weight: normal;
  /* font-family: "Red Hat Display", sans-serif; */
}
.footerItems ul li {
  text-decoration: none;
  list-style: none;
  margin: 1rem 0;
  font-size: 0.9rem;
  color: white;
  /* font-family: "Red Hat Display", sans-serif; */
  font-weight: normal;
}
.footerItems ul li:hover,
.socialMediaIcon:hover {
  cursor: pointer;
  color: orange;
  transition: 500ms;
}
.newsLetterBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 6vh;
  /* width: 16rem; */
  background-color: #1a4c2d;
  /* border: 2px solid red; */
  /* border-radius: 1.8rem; */
  box-sizing: border-box;
  position: relative;
}
.newsLetterBoxInput {
  width: 100%;
  height: 1.5vh;
  /* border: 2px solid orange; */
  padding: 1rem 1rem;
  border: none;
  border-radius: 2rem;
  /* border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem; */
  font-size: 1rem;
  display: flex;
}

.subscribeNewsLetterBtn {
  cursor: pointer;
  position: absolute;
  right: 0%;
  margin: 0.2rem;
  width: 2.5rem;
  height: 2.5rem;
  /* border: 2px solid yellow; */
  justify-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0.1rem; */
  padding: 0.5rem;
  border-radius: 2rem;
  background-color: #1a4c2d;
  color: white;
}
.socialMediaIconsBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a4c2d;
  color: white;
  padding: 2rem;
  padding-top: 1rem;
}
.socialMediaIcon {
  padding: 0.8rem;
  cursor: pointer;
}
.socialMediaIconsBox a {
  color: white;
}

.copyrightStatement {
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #1a4c2d;
  text-align: left;
  padding: 2rem 7rem;
}
.termsMain {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.terms-items {
  width: 70vw;
  /* border: 2px solid red; */
  margin-bottom: 3rem;
}
.terms-items li,
.terms-items p,
.terms-items h4 {
  font-size: 17px;
  /* border: 2px solid yellow; */
  padding: 1rem;
  text-align: left;
  list-style: disc;
  line-height: 2rem;
  color: black;
}
.terms-items li {
  color: rgba(0, 0, 0, 0.7);
}
.terms-items h4 {
  color: rgba(0, 0, 0, 0.9);
}
.mobileFooterHeadings {
  display: none;
}
@media (max-width: 650px) {
  .footerItems h3 {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    height: 30px;
    /* text-decoration: underline; */
    font-size: 12px;
    margin: 0;
    /* display: none; */
  }
  /* .mobileFooterHeadings {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    background-color: #1a4c2d;
    margin: 0;
    justify-content: center;
    padding: 20px 0;
    padding-bottom: 0px;
  }
  .mobileFooterHeadings h3 {
    font-size: 11px;
    padding: 5px;
    margin: 2px;
    border: 1px solid yellow;
    color: white;
  } */
  .footerItems ul li,
  .footerItems ul a {
    font-size: 9px;
    margin: 5px 0;
  }
  /* .footerOuterContainer {
  
  } */
  .footerMain {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;

    padding-top: 40px;
    padding-bottom: 20px;
  }
  .footerItems {
    /* width: 22%; */
    margin: 0;
    padding: 0;
    /* border: 2px solid red; */
    /* height: 10rem; */
  }
  .newsLetterBox {
    display: none;
  }
}
