.shopPageMain {
  position: relative;
}
.blackBox {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.632);
}
.hide {
  display: none;
}
.shopPage {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  /* margin-top: 2vh; */
  margin: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  /* background-color: #fffbef; */
}
.headingBox {
  position: sticky;
  z-index: 5;
}

.sortBtnMainBox {
  display: flex;
  /* width: 60vw; */
  /* border: 2px solid red; */
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 0 1rem; */
  margin-top: 3rem;
  /* margin-left: 7rem; */

  /* border: 2px solid red; */
}
.sortBtnList {
  /* border: 2px solid orange; */
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.mainFilterBox {
  display: flex;
  justify-content: space-between;
  /* position: relative; */
  /* padding: 1rem; */
  width: 84vw;
  /* border: 2px solid yellow; */
  /* position: relative; */
  flex-wrap: wrap;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.mainFilterBox p {
  padding: 1rem;
  /* margin: 1rem; */
  /* padding-right: 1.5rem; */
  list-style: none;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.mainFilterBox p:hover {
  background-color: white;
}
.labelBox {
  display: flex;
}
.labelBox p {
  cursor: pointer;
  z-index: 3;
}

.labelBoxDrop {
  width: 50vw;
  height: 30vh;
  /* background-color: aqua; */
  background-color: white;
  /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5); */
  display: none;
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  z-index: 4;
  /* right: 0; */
}
.mainFilterBox p:hover .labelBoxDrop,
.blackBox:hover {
  display: block;
}

.labelBoxDropList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* width: 100%; */
}
.labelBoxDropList p {
  cursor: pointer;
  height: 2vh;
  width: 40%;
  text-align: start;
  border: none;
}

.new {
  /* position: absolute; */
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.532);
}
.sortByBox {
  position: absolute;
  display: block;
  right: 10%;
  /* border: 2px solid black; */
  /* justify-content: flex-end; */
}
.drop2 {
  width: 100%;
  height: 30vh;
  right: 100%;
  bottom: 0;
  /* left: 0; */
}
#sortBy,
#sortBy p {
  border: none;
  text-align: left;
  padding-left: 1rem;
}
#sortBy:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}
.checkBox {
  margin: 0 1rem 0 0;
}
.filterArrow {
  position: absolute;
  top: 0;
  right: 0;
  /* width: 2rem; */
  /* height: 2rem; */
  background-color: white;
}
.box8 {
  display: flex;
  height: 3rem;
  width: 40%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  justify-content: flex-start;
  /* border: 2px solid red; */
}
.outerFilterBox {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* border: 2px solid yellow;   */
}
.checkWhite {
  width: 1rem;
  height: 1rem;
  border: 1px solid black;
  background-color: white;
}
.checkGreen {
  width: 1rem;
  height: 1rem;
  border: 1px solid black;
  background-color: #1a4c2d;
}
.redMarkFilter {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0%;
  left: 0;
  border-radius: 50%;
  background-color: red;
}
/* .filterSection {
  position: absolute;
  width: 80vw;
  height: 40vh;
  margin-top: 4rem;
  z-index: 5;
  bottom: 0;
  left: 0;
  background-color: grey;
} */

/* .sortBtn {
  margin: 0 1rem;
  padding: 1rem;
  position: relative;
}
.select {
  font-size: small;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
} */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10rem;
  background-color: #1a4c2d;
}
.loading h1 {
  color: white;
}
.productsImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 32vh;
  aspect-ratio: 1;
  /* height: 38vh;  */
  margin: 1rem;
  position: relative;
}
.productsHeartIcon {
  position: absolute;
  padding: 1rem;
  top: 0;
  right: 0;
}

/* .productsImages:hover {
  transform: scale(1.1);
} */
.container {
  width: 38vh;
  /* width: 20vw; */
  /* height: 100%; */
  height: 470px;
  position: relative;
  /* font-family: "Red Hat Display, sans-serif"; */
  margin: 2rem 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  /* box-shadow: 0 10px 20px rgba(40, 40, 40, 0.1); */
  /* transition: ease-in-out 150ms; */
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.container:hover {
  box-shadow: 0 5px 15px rgba(40, 40, 40, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: black;
}
.container h3 {
  /* font-family: "Cardo sans-serif"; */
  /* letter-spacing: 0.5px; */
  text-transform: capitalize;
  font-size: 1.3rem;
  margin: 0.5rem 1rem;
  /* padding-bottom: 0.5rem; */
  font-weight: 450;
  color: rgba(0, 0, 0, 0.85);
  text-align: start;
}
.container p {
  font-size: 1.4rem;
  text-align: start;
  padding: 0 1rem;
  font-weight: 650;
  color: rgba(0, 0, 0, 0.75);
}
.container a {
  text-decoration: none;
}

.shoppageProductDescriptionBox p {
  /* font-family: Monsterrat Light; */
  font-size: 1rem;
  font-weight: lighter;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
  color: rgba(0, 0, 0, 0.65);
  /* letter-spacing: 0.05rem; */
}
.mobileFilter {
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1a4c2d;
  /* position: relative; */
}
.mobileFilter button {
  padding: 0.5rem 1rem;
  margin: 0 2rem;
  z-index: 2;
  /* position: relative; */
}
.mobileDropdownFilters {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  text-align: left;
  top: 10px;
  padding-top: 150px;
  background-color: #1a4c2d;
}
.mobileDropdownFilters li {
  color: white;
  margin-left: 2rem;
  margin: 1rem;
  padding: 1rem;
  background-color: rgb(10, 10, 10);
}
/* .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  background: black;
  cursor: pointer;
  z-index: 1;
} */
/* .content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: white;
  text-align: center;
} */
/* .container:hover .overlay {
  opacity: 0.8;
} */
/* .overlayContentItems {
  padding: 0.5rem;
  color: black;
  margin: 0.5rem;
  background-color: white;
} */
/* .overlayContentItems:hover {
  background-color: #1a4c2d;
  color: white;
  opacity: 1;
} */
/* .iAmImage {
  width: 500px;
  height: 500px;
  border: 2px solid green;
} */
@media (max-width: 650px) {
  .filterBox {
    display: none;
  }
}
