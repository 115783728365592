.profilePageMainBox {
  width: 100%;
  /* height: 40rem; */
  box-sizing: border-box;
}
.profileImage {
  width: 16%;
  border-radius: 50%;
  border: 1px solid peru;
}
.profileBox {
  /* margin: 2rem 1rem; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  justify-content: center;
  padding: 1rem 5rem;
  text-align: left;
  /* border-top: 10px solid peru; */
  /* border-bottom: 10px solid peru; */

  box-shadow: 0 -3em 3em rgba(251, 232, 63, 0.294), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.316);

  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://cdn.pixabay.com/photo/2015/10/15/21/09/texture-990071_960_720.jpg");
  background-image: url("https://images.pexels.com/photos/4252163/pexels-photo-4252163.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-image: url("https://images.pexels.com/photos/7605641/pexels-photo-7605641.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  box-sizing: border-box;
}
.profileBox h2,
.profileBox h3 {
  color: white;
  margin: 0.5rem 0;
}
.profileBox2 {
  margin: 2rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-around;
  padding: 1rem 5rem;
  /* border: 2px solid peru; */
  box-sizing: border-box;
  margin-bottom: 10rem;
}
.profileMyDetailsBox {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  justify-content: flex-start;
  text-align: left;
  padding: 1rem;
  margin: 1rem 2rem;

  /* border: 1px solid yellow; */
}

.detailsTypeBox {
  width: 10rem;
  text-align: left;
  padding: 1rem;
  height: 25rem;
  margin: 1rem;
  border-right: 3px solid peru;
}
.detailsTypeBox h4 {
  padding: 1rem;
  border-bottom: 1px solid grey;
}
#greenColouredBorder {
  border-color: #1a4c2d;
  border-bottom: 3px solid #1a4c2d;
}

.detailsTypeBox h4:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.detailsBox {
  text-align: left;
  margin: 1rem;
  padding: 2rem;
  width: 70%;
  height: 25rem;
  border: 1px solid peru;
  position: relative;
}
.createdOnBox {
  text-align: right;
  padding: 2rem;
  position: absolute;
  bottom: 0;
  right: 0;
}
.updateAccountDetailsMainBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}
.updateAccountDetailsMainBox button {
  background: linear-gradient(
    90deg,
    rgba(41, 0, 0, 0.8491771708683473) 0%,
    rgba(121, 48, 9, 0.7959558823529411) 35%,
    rgba(255, 145, 0, 0.8519782913165266) 100%
  );
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.5rem;
  font-weight: bolder;
  border-color: white;
}
.updateAccountDetailsMainBox button:hover {
  border-color: black;
  cursor: pointer;
  color: white;
}
.updateAccountDetailsPopupMainBox {
  position: absolute;
  width: 40vw;
  /* aspect-ratio: 1; */
  height: 60vh;
  border: 5px solid peru;
  background-color: white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.updateAccountDetailsPopupCloseBtn {
  margin-top: 5rem;
  width: 2rem;
  cursor: pointer;
}
.updateAccountDetailsPopupCloseBtn:hover {
  color: peru;
}
.updateAccountDetailsPopupMainBox input {
  width: 15rem;
  margin: 2rem 0;
  font-size: larger;
  padding: 0.5rem;
}
.updateAccountDetailsPopupMainBox button {
  width: 17rem;
  padding: 0.5rem;
  background: linear-gradient(
    90deg,
    rgba(41, 0, 0, 0.8491771708683473) 0%,
    rgba(121, 48, 9, 0.7959558823529411) 35%,
    rgba(255, 145, 0, 0.8519782913165266) 100%
  );
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.5rem;
  font-weight: bolder;
  border-color: white;
}
.updateAccountDetailsPopupMainBox button:hover {
  border-color: black;
  cursor: pointer;
  color: white;
}
.customerDetailsMobileview {
  display: none;
}
.customerDetailsEditBoxMain {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.2fr;
  align-content: center;
  border: 2px solid red;
}
.customerDetailsEditBoxMain h4 {
  border: 2px solid red;
  margin: 5px;
}

@media (max-width: 650px) {
  .profileBox {
    padding: 0;
  }
  .profileBox img {
    width: 100px;
    height: 100px;
    margin: 0.5rem 10rem;
    margin-top: 1rem;
  }
  .profileMyDetailsBox {
    margin: 5px;
    font-size: small;
  }
  .profileMyDetailsBox h3 {
    margin: 2px 0;
    line-height: 1.5rem;
  }
  .customerDetailsDesktop {
    display: none;
  }
  .customerDetailsMobileview {
    display: block;
  }
  .customerProfileBox {
    margin: 2rem 0;
  }
  .customerProfileBox h3 {
    margin: 0.5rem 0;
    padding: 0.5rem;
    position: relative;
  }
  .customerDetailsMobileDrawer {
    display: block;
  }
  .updateAccountDetailsMainBox {
    display: flex;
    flex-direction: column;
  }
  .updateAccountDetailsMainBox button {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
  .detailsBoxMobileView {
    height: auto;
  }
  .detailsBoxMobileView button {
    margin: 2rem 0;
  }
  .updateAccountDetailsPopupMainBox2 {
    /* margin: 25%; */
    width: 100%;
    box-sizing: border-box;
    position: sticky;
    top: 25%;
  }
}
