.productDetailsMainBox {
  width: 100%;
  /* height: 100vh; */
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: flex;
  box-sizing: border-box;
}
.productImagesBox {
  /* width: 50vw; */
  /* height: 65vh; */
  /* border: 2px solid aqua; */
  display: flex;
  justify-content: flex-end;
}
.productDescriptionBox {
  /* width: 50vw; */
  /* height: 65vh; */
  margin-left: 5rem;
  /* font-family: "Red Hat Display, sans-serif"; */
  /* border: 2px solid aqua; */
}
.smallBoxes {
  /* height: 400px; */
  width: 200px;
  margin-right: 2rem;
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.largeBox {
  width: 25rem;
  aspect-ratio: 1;
  /* aspect-ratio: 1; Important try

  overflow: hidden;
  box-shadow: 0 10px 20px rgba(40, 40, 40, 0.2);
  border: 2px solid red;
  /* padding: 1rem; */
  /* border: 2px solid green; */
}

.smallBox {
  /* width: 80px; */
  width: 5rem;
  aspect-ratio: 1;
  padding: 0.2rem;
  box-sizing: border-box;
  /* border: 2px solid red; */
  box-shadow: 0 10px 20px rgba(40, 40, 40, 0.2);
  margin: 0.5rem;
  margin-top: 0rem;
  cursor: pointer;
}
.iAmImage {
  width: 100%;
  height: 100%;
  /* border: 2px solid yellow; */
}
.productDescriptionBox {
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  /* font-family: "Red Hat Display, sans-serif"; */
  text-align: left;
  padding-right: 3rem;
}
.titleAndDetails {
  position: relative;
  /* border: 2px solid orange; */
}
.titleAndDetails h1 {
  font-size: 31px;
  margin-bottom: 1rem;
}
.titleAndDetails p {
  color: gray;
  width: 100%;
  margin-left: 1rem;
  font-size: 17px;
  line-height: 1.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.329);
}
.priceBox {
  margin: 1rem 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
}
.priceBox h2 {
  color: #1a4c2d;
  font-size: 24px;
  margin-bottom: 0.5rem;
}
.priceBox span {
  color: gray;
  font-size: 17px;
}
#priceBreakupOption {
  margin-left: 1rem;
  display: inline-flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.productDescriptionBoxDetails {
  display: flex;
  position: relative;
}
.productDescriptionLink {
  position: absolute;
  right: 0;
  bottom: 0;
}
.findYourSizeBox {
  display: flex;
  padding: 0 1rem;
  /* margin-bottom: 1rem; */
}
.findYourSizeBox button {
  padding: 0 0.3rem;
  margin: 0 1rem;
  background-color: rgb(255, 250, 220);
  border: none;
  /* color: #1a4c2d; */
}
.findYourSizeBox button:hover {
  cursor: pointer;
  font-weight: 700;
  color: #1a4c2d;
  /* border: 2px solid #1a4c2d; */
}
.addToCartBox {
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 2px solid black; */
  /* padding: 1rem; */
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
}
.countBtn {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-weight: bolder;
  font-size: 1rem;
  cursor: pointer;
  border: none;
}
.countInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  margin: 0 10px;
}
.addToCartBtn {
  display: flex;
  font-size: 14px;
  align-items: center;
  font-weight: bolder;
  margin: 0 1rem;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  color: white;
  background-color: #1a4c2d;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.addToCartBtn p {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.addToCartBtn::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: white;

  z-index: -1;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 300ms ease-in-out;
}
.addToCartBtn:hover::after {
  transform: scaleX(1);
  transition: transform 300ms ease-in-out;
}
.addToCartBtn:hover {
  transition: color 200ms ease-in-out;
  color: #1a4c2d;
  font-weight: bolder;
}
.addToCartIcon {
  margin-left: 1rem;
  cursor: pointer;
}
.addToCartIcon:hover {
  color: #1a4c2d;
}
.whyUsBox {
  width: 100%;
  /* height: 20vh; */
  /* height: 10vh; */
  /* background-image: url("../../Images/whyUs3.png"); */
  /* display: grid; */
  display: flex;
  padding: 1rem 0;
  /* grid-gap: 10px; */
  grid-template-columns: repeat(4, 1fr);
  /* background-size: contain; */
  /* background-repeat: no-repeat; */
  align-items: center;
  text-align: center;
  /* justify-content: center; */
  justify-content: center;
  margin: 1rem;
  margin-top: 0;
  background-color: rgb(255, 250, 220);
}
.whyUsItems {
  /* width: 150px; */
  /* height: 100px; */
  padding: 0 1rem;
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid grey;
  /* border-left: 1px solid red; */
  align-items: center;
}
.whyUsItems h3 {
  /* margin-top: 2rem; */
  font-size: 16px;
}
.priceBreakUpMainBox {
  /* height: 100vh;  */
  background-color: rgba(255, 250, 220, 0.5);
  padding: 5rem 5rem;
  /* font-family: "Red Hat Display, sans-serif"; */
}
.priceBreakUpHeading {
  display: flex;
  cursor: pointer;
  padding-bottom: 0rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  box-sizing: border-box;
}
.priceBreakUpItems {
  /* border-bottom: 1px solid rgba(128, 128, 128, 0.3); */
  padding-bottom: 0.5rem;
  margin: 0rem 1rem;
}
.borderPresent {
  color: #1a4c2d;
  border-bottom: 2px solid #1a4c2d;
}
.priceBreakUpItems:hover {
  color: #1a4c2d;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  /* border-bottom: 1px solid rgba(2, 47, 0, 0.855); */
  border-bottom: 2px solid #1a4c2d;
}
.blankBox {
  width: 70vw;
  background-color: #1a4c2d;
  border-top: 1px solid rgba(128, 128, 128, 0.3);
}
.priceDetailsBoxBelow {
  /* width: 70vw; */
  /* height: 40vh; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem 0;
  /* flex-wrap: wrap; */
  /* justify-content: center;  */
  align-items: center;
  /* background-color: red; */
  padding-bottom: 4rem;
  border-bottom: 3px solid rgba(128, 128, 128, 0.2);
}
.priceDetailsBoxBelow li {
  padding: 1rem 0;
  font-size: 1rem;
  font-weight: bold;
  color: black;
  /* padding-left: 5rem; */
  margin-left: 4rem;
  list-style: none;
  text-align: left;
}
.table1 {
  width: 100%;
  /* padding: 0 4rem;  */
}

.priceBreakUpBoxBelow {
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 3px solid rgba(128, 128, 128, 0.2);
}
.productDescriptionBoxBelow {
  text-align: start;
  line-height: 2rem;
  margin-top: 2rem;
  color: rgba(128, 128, 128, 0.8);
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 3px solid rgba(128, 128, 128, 0.3);
}
.reviewsBoxBelow {
  width: 70vw;
  /* height: 40vh; */
  /* background-color: powderblue; */
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reviewsBoxBelow h2 {
  color: rgba(0, 0, 0, 0.7);
}
#reviewTitle {
  color: black;
  font-weight: bold;
}
.priceBreakUpBoxBelow table {
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.8);
  border: 0.5px solid rgba(128, 128, 128, 0.2);
  text-align: start;
}
.priceBreakUpBoxBelow th {
  text-align: start;
  padding: 1rem;
  font-size: 1.2rem;
  border: 0.5px solid rgba(128, 128, 128, 0.2);
}
.priceBreakUpBoxBelow td {
  padding: 1rem;
  font-size: 1.1rem;
  border: 0.5px solid rgba(128, 128, 128, 0.2);
}

.reviewSection {
  display: flex;
  align-items: center;
  text-align: start;
  padding: 1rem;
  border-bottom: 2px solid rgba(128, 128, 128, 0.3);
  width: 80vw;
}
.reviewDp {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}
.reviewDetails {
  /* border: 2px solid #1a4c2d; */
  padding: 0 1rem;
}
.reviewDetails p,
.reviewDetails h3 {
  margin: 1rem 0;
  color: rgba(0, 0, 0, 0.8);
}
#reviewDate {
  color: rgba(128, 128, 128, 0.8);
}
.reviewSectionRating {
  display: flex;
  justify-content: flex-end;
  width: 40%;
}
.addReviewMain {
  padding: 1rem 0;
  width: 80vw;
  /* height: 20vh; */
  margin-top: 2rem;
  /* margin-bottom: 20rem; */
  /* border: 2px solid yellowgreen; */
  border-bottom: 3px solid rgba(128, 128, 128, 0.3);
  text-align: start;
}
.addReviewMain h2 {
  padding: 1rem 0;
}
.reviewInputBox {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.reviewInput {
  width: 78vw;
  /* height: 10rem; */
  margin-top: 1rem;
  border-radius: 0.5rem;
  text-align: start;
  /* font-family: "Red Hat Display, sans-serif"; */
  font-size: 1.2rem;
  padding: 1rem;
}

.reviewInputDetailsBox {
  display: flex;
  justify-content: space-between;
}
.reviewInputDetails {
  width: 47%;
  height: 2rem;
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid grey;
}
.submitReview {
  margin: 1rem 0;
  font-size: 1rem;
}
.ProductDetailsBottomBox {
  margin: 3rem 0;
}
.ProductDetailsBottomBox h1 {
  /* font-family: "Red Hat Display, sans-serif"; */
  font-size: 3rem;
  font-weight: lighter;
}
/* .container,
.ProductDetailsBottomBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  height: 100%;
} */

/* .commentsSlide {
  margin: 3rem 5rem;
} */
/* @media screen and (max-width: 1200px) {
  .smallBoxes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: O.5rem;
  }
  .largeBox {
    width: 100%;
  }
} */
@media screen and (max-width: 1000px) {
  .productDetailsMainBox,
  .productImagesBox {
    display: flex;
    flex-direction: column;
    /* marginleft: 0; */
    padding: 0;
  }
  .smallBox {
    width: 7rem;
  }
  .smallBoxes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: O.5rem;
  }
  .largeBox {
    width: 100%;
  }
  .productDescriptionBox {
    margin-left: 0;
  }
  .priceBreakUpMainBox {
    padding: 1rem;
  }
  .priceBreakUpHeading {
    width: 100%;
    /* height: 10rem; */
    padding-bottom: 0;
    margin-bottom: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: small;
  }
  .priceDetailsBoxBelow li {
    margin-left: 0;
    font-size: small;
  }

  .priceBreakUpItems {
    margin: 2px 0;
  }
  .priceBreakUpItems h3 {
    margin: 0;
    padding: 0.5rem;
  }
  .borderPresent {
    background-color: #1a4c2d37;
    padding: 0.5rem;
    margin: 0;
  }
  .priceBreakUpBoxBelow table {
    width: 100%;
    padding: 0;
    /* display: grid; */
    /* grid-template-columns: repeat(5, 1fr); */
    /* flex-direction: column; */
  }
  .priceBreakUpBoxBelow table th {
    margin: 2rem 0;
    padding: 2rem 0;
    font-size: smaller;
  }
  .priceBreakUpBoxBelow table td {
    padding: 2px;
    font-size: smaller;
    /* margin-left: 0; */
  }
  .productDescriptionBoxBelow {
    font-size: small;
  }
  .reviewsBoxBelow {
    display: flex;
    flex-direction: column;
  }

  .reviewSection {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }
  .addReviewMain {
    margin-left: 1rem;
  }
  .reviewInputDetailsBox input {
    margin: 1rem;
  }
  .addToCartBtn {
    display: flex;
    text-align: right;
    padding: 5px 10px;
    font-size: 14px;
  }
}
