.sideBarMainBox {
  /* changed now */
  width: 15%;
  height: 100vh;
  background-color: white;
  /* margin-top: 5rem; */

  z-index: 2;
  /* border: 2px solid red; */
  text-align: left;
  /* padding: 1rem; */
  /* padding-top: 3rem; */
  color: rgb(110, 110, 110);
  position: fixed;
}
.sideBarMainBox li {
  margin-left: 1.25rem;
  margin-top: 1rem;
  font-weight: 500;
  list-style: none;
}
.sideBarMainBox h3:hover,
.sideBarMainBox li:hover {
  color: #00acc1;
}
.sideBarMainBox a {
  text-decoration: none;
  color: rgb(110, 110, 110);
}
/* .sideBarMainBox a:active {
  color: #00acc1;
} 
.
.sideBarMainBox .active-link {
  color: #00acc1;
} */
.sideBarLogoBox a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 0;
}
.adminSideBarLogo {
  width: 75%;
  /* margin-left: 25%; */
  margin-bottom: 10px;
}
.adminSideBarLogoSmall {
  width: 20%;
  margin-left: 115%;
  /* height: 50%; */
  justify-content: flex-end;
  align-self: flex-end;
}
.adminSidebarDropdownList {
  cursor: pointer;
  padding-bottom: 0;
}
.adminSidebarDropdownList li {
  margin-left: 2rem;
  font-size: 0.9rem;
  /* transition: all 1s ease-in-out; */
}

/* GPT */

.dropdown-container {
  width: 100%;
}
.sidebar {
  width: 175px;
}
.category-item {
  margin-bottom: 5px;
}
.category-item li {
  margin: 5px;
}

.category-button {
  display: block;
  /* width: 0%; */
  padding: 5px;
  /* background-color: #f0f0f0; */
  border: none;
  font-size: 13px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.7s;
}

.category-button.active {
  background-color: #e0e0e0;
  color: black;
}

.category-list-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s;
}

.category-list-container.open {
  max-height: 1000px; /* Adjust the value as per your content height */
}

.category-list {
  /* margin-top: 5px; */
  padding: 0;
  list-style-type: none;
  background-color: #ffffff;
  /* border: 1px solid #cccccc; */
}

.category-list li {
  /* font-size: 0.9rem; */
  font-size: 12px;
  margin: 10px;
  margin-left: 30px;
  cursor: pointer;
}

.category-list li:hover {
  background-color: #f0f0f0;
}
.sidebarDropdownIcon {
  position: absolute;
  right: 30px;
  /* border: 2px solid orange; */
}
