.hide {
  display: none;
}
.mobileNavbarMainBox {
  /* border: 2px solid grey; */
  padding: 1rem;
  height: 100%;
  /* margin: 1rem; */
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
}
h3 a:link {
  padding: 0;
  margin: 0;
}
.mobileNavbarTop {
  width: 70vw;
  height: 20vh;
  background-color: #1a4c2d;
}
.mobileNavbarTopOffer {
  width: 100%;
  height: 100%;
}
.mobileNavbarBody {
  margin-top: 1rem;
}
.mobileMenuList h3 {
  color: #1a4c2d;
  font-weight: normal;
  font-size: 0.8rem;
  padding: 0.5rem;
}
.mobileMenuList h3:hover {
  font-weight: bolder;
}
.mobileMenuList a {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  margin: 0.5rem;
  font-weight: normal;
  padding: 0 1rem;
  color: #1a4c2d;
  font-size: small;
  position: relative;
  text-decoration: none;
}
.mobileNavbarDropdownIcon {
  position: absolute;
  right: 2rem;
}
.allCategoriesList {
  padding-bottom: 1rem;
}

.addToCartBtn {
  font-size: xx-small;
}
.mobileNavbarBottomButtons {
  display: flex;
  margin-top: 20vh;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5vh;
}
.mobileNavbarBottom h3 {
  margin: 0.5rem;
  background-color: rgba(128, 128, 128, 0.119);
}
